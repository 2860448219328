/*
 * @Author: Han
 * @Date: 2022-05-12 18:58:50
 * @LastEditors: Han
 * @LastEditTime: 2022-05-17 13:19:37
 * @FilePath: \newAdmin\src\plugins\http.js
 */
import axios from "./axios/axios";

// 封装Get请求 Post请求
const GET = (url, params) => axios.get(url, { params });

// 请求体
export const sendSteps = (params) => GET("misteps", params);
