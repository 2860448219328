/*
 * @Author: Han
 * @Date: 2022-05-11 14:15:57
 * @LastEditors: Han
 * @LastEditTime: 2022-05-17 16:03:25
 * @FilePath: \newAdmin\src\plugins\element-ui.js
 */
import Vue from "vue";
import { Button, Notification, Tabs, TabPane, Input, InputNumber, Link } from "element-ui";

const elementArr = { Button, Tabs, TabPane, Input, InputNumber, Link };

Object.values(elementArr).forEach((itm) => {
  Vue.use(itm);
});

Vue.prototype.$notify = Notification;
