<!--
 * @Author: Han
 * @Date: 2022-05-11 14:13:56
 * @LastEditors: Han
 * @LastEditTime: 2022-05-12 18:42:55
 * @FilePath: \GithubProject\apiadmin\src\App.vue
-->
<template>
  <div class="vvhan-com">
    <h3>微信一键刷步数</h3>
    <el-tabs v-model="activeName">
      <el-tab-pane label="一键刷步数" name="0">
        <div class="pannel-head">
          <p>️️️⚠️ 请确保小米运动APP绑定了微信运动</p>
          <p>✅ 步数范围早最小为1最大为99999</p>
        </div>
        <div class="panel-item">
          <el-input v-model="mobil" placeholder="小米运动帐号/手机号" oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
        </div>
        <div class="panel-item">
          <el-input v-model="password" placeholder="小米运动密码" show-password></el-input>
        </div>
        <div class="panel-item">
          <el-input-number label="步数" v-model="step" :min="1" :max="99999"></el-input-number>
        </div>
        <div class="panel-item">
          <el-button type="primary" :loading="orPost" style="width: 100%" @click="sendStepsFn">一键刷取</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="使用教程" name="1">
        <div class="user-tips">
          <p>️⭕️ 下载好Zepp Life(原小米运动)，注册登陆====点击我的====第三方接入====绑定微信即可一键刷步</p>
          <p>
            ✅ IOS下载小米运动APP： <el-link type="primary" href="itms-apps://itunes.apple.com/app/id938688461" target="_blank">AppStore下载</el-link> 安卓下载小米运动APP：
            <el-link type="primary" href="https://app.mi.com/details?id=com.xiaomi.hm.health" target="_blank">官方下载</el-link>
          </p>
        </div>
        <img src="./assets/steps.png" style="width: 100%" />
      </el-tab-pane>
    </el-tabs>
    <div class="vvhan-foot">
      <p>
        2022-2022 <a href="https://www.vvhan.com/" target="_blank"><strong>韩小韩博客</strong><br /></a>ㅤMail:<strong style="color: red">web@4ce.cn</strong>
      </p>
    </div>
  </div>
</template>
<script>
import { sendSteps } from "@/plugins/http";
export default {
  data() {
    return {
      activeName: "0",
      mobil: "",
      password: "",
      step: 1,
      orPost: false,
    };
  },
  methods: {
    async sendStepsFn() {
      if (this.orPost) return 0;
      if (this.mobil.length < 6 || this.password.length < 6) return this.$notify({ title: "Error", message: "请输入正确的帐号密码", type: "error", offset: 66, duration: 1666 });
      this.orPost = true;
      const { mobil, password, step } = this;
      const _res = await sendSteps({
        mobil,
        password,
        step,
      });
      this.orPost = false;
      console.log(_res);
    },
  },
};
</script>

<style lang="scss" scoped>
.vvhan-com {
  user-select: none;
  margin: auto;
  width: 56%;

  .panel-item {
    padding-top: 16px;
  }

  .vvhan-foot {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 22%;
    width: 56%;
    text-align: center;
    color: #000;
    font-size: 14px;
    line-height: 28px;

    a {
      color: #409eff;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 960px) {
  .vvhan-com {
    width: 94%;
  }
  .vvhan-foot {
    width: 94%;
    left: 3%;
  }
}
</style>
